import React from 'react';

export const NoDetailColorText = 'There are no possibility to choose detail Color for this print';

export const Text = () => {
  return (
    <div className="main-step active">
      <div className="main-row">
        <div className="main-col model active">
          {NoDetailColorText}
        </div>
      </div>
    </div>
  );
}
