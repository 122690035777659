import React from 'react';
import classNames from 'classnames';

export const BigColorStepper = ({ items, onClick, currentId }) => {
  return (
    <div className="colors-full colors-full-big">
      {items.map((item) => (
        <div className={classNames('colors-col', {'active': item.id === currentId})} key={`${item.id}${Math.random()}`} onClick={() => onClick(item)}>
          <div className="colors-block" style={{ background: item.code }} />
          <span className="colors-name">{item.name}</span>
        </div>
      ))}
    </div>
  )
}
