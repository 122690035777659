import React from 'react';
import classNames from 'classnames';

export const ColorStepper = ({ items, onClick, currentId, codeProperty = 'codeMain' }) => {
  return (
    <div className="colors-tabs active">
      <div className="colors-tab active" id="colors-tab-1">
        <div className="colors-row">
          {items.map((item, index) => (
            <div className={classNames('colors-col', {'active': item.id === currentId})} key={`${index}${Math.random()}`} onClick={() => onClick(item)}>
              <div className="colors-block" style={{ backgroundColor: item[codeProperty] }}/>
              <span className="colors-name">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
