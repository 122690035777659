import * as THREE from 'three';
import React from 'react'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default class View extends React.Component {
  baseMaterial = new THREE.MeshStandardMaterial({
    transparent: true,
    color: "#EDEDEB",
    metalness: 0.0,
    normalScale: new THREE.Vector2(0.1, 0.1),
    map: null,
  });
  strapMaterial = new THREE.MeshStandardMaterial({
    transparent: false,
    color: "#D3D2A6",
    metalness: 0.1,
    roughness: 0.9,
    map: null
  });
  overlayMaterial = new THREE.MeshStandardMaterial({
    transparent: true,
    color: "#2C2626",
    map: null,
    alphaTest: 0.001,
    side: THREE.FrontSide
  });

  canvasSize = function () {
    return { width: this.mount.current?.clientWidth ?? 1, height: this.mount.current?.clientHeight ?? 1 };
  }
  Rect = () => this.renderer.domElement.getBoundingClientRect();

  mount = React.createRef();
  frameId = -1;

  loader = null;

  constructor() {
    super()

    const canvasSize = this.canvasSize();

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, canvasSize.width / canvasSize.height, 0.01, 100);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMappingExposure = 1.0;
    renderer.setClearAlpha(0);
    renderer.setSize(canvasSize.width, canvasSize.height);
    renderer.setPixelRatio(window.devicePixelRatio);

    window.addEventListener('resize', () => this.onWindowResize(), false);

    scene.add(new THREE.AmbientLight(0xffffff));
    let light = new THREE.PointLight( 0xffffff, 1, 0, 2 )
    light.position.set(0, 0, 0.75);
    scene.add(  light);
    light = new THREE.PointLight( 0xffffff, 1, 0, 2 )
    light.position.set(0, 0, -0.75);
    scene.add(  light);

    this.scene = scene;
    this.renderer = renderer;
    this.camera = camera;
    this.controls = this.createControls();

    this.overlayMaterial.polygonOffset = true;
    this.overlayMaterial.polygonOffsetFactor = 0.0;

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath( '/draco/' );
    
    this.loader = new GLTFLoader();
    this.loader.setMeshoptDecoder(MeshoptDecoder);
    this.loader.setDRACOLoader( this.dracoLoader );

    this.loadBackground();
  }

  async loadBackground() {
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    const envScene = new DebugEnvironment();
    const generatedCubeRenderTarget = pmremGenerator.fromScene(envScene);

    //this.scene.environment = generatedCubeRenderTarget.texture;
  }


  render() {
    const style = { width: "100%", height: "100%" };
    return <div style={style} ref={this.mount}/>;
  }

  componentDidMount() {
    this.mount.current.appendChild(this.renderer.domElement);
    this.onWindowResize();
    this.animate();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frameId);
    this.mount.current.removeChild(this.renderer.domElement);
  }

  onWindowResize() {
    const canvasSize = this.canvasSize();

    const width = canvasSize.width;
    const height = canvasSize.height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  createControls() {
    const c = new OrbitControls(this.camera, this.renderer.domElement);
    c.enableKeys = false;
    c.minDistance = 2.0;
    c.maxDistance = 3.0;
    c.rotateSpeed = 1;
    c.enableZoom = true;
    c.enablePan = false;

    return c;
  }


  async loadModelAsync(url) {
    
    
    const gltf = await this.loader.loadAsync(url);
    const model = gltf.scene;
    model.traverse((mesh) => {
      if (mesh != null) {
        
        const mat = mesh.material;

        if (mesh.name.includes("Base") && mat != null) {

          
          const geo = mesh.geometry;

          geo.clearGroups();
          geo.addGroup(0, 930000 * 2, 0);
          geo.addGroup(0, 930000 * 2, 1);

          mesh.material = [this.baseMaterial, this.overlayMaterial];

        }
        if (mesh.name.includes("Strap01") || mesh.name.includes("Strap02") && mat != null) {

          mesh.material = this.strapMaterial;

        }

      }
    })

    if (this.model != null) {
      this.scene.remove(this.model);
    }
    this.scene.add(model);
    
    return model;
  }

  animate() {
    this.frameId = requestAnimationFrame(() => this.animate());

    this.controls.update();

    this.renderer.render(this.scene, this.camera);
  }


}
