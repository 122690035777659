import React from 'react';

export const Menu = () => {
  return (
    <div className="menu">
      <div className="menu-top">
        <span className="menu-title">Pages</span>
        <i className="hb-ico menu-close"/>
      </div>
      <ul className="menu-list">
        <li><a href="/" className="active">Model</a></li>
        <li><a href="/">Design</a></li>
        <li><a href="/">Settings</a></li>
        <li><a href="/">Main Color</a></li>
        <li><a href="/">Strap Color</a></li>
        <li><a href="/">Detail Color</a></li>
        <li><a href="/">Finish</a></li>
      </ul>
    </div>
  )
}
