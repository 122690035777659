import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { ExportUtils } from '../../exportUtils';
import { ModelView } from '../model-view/ModelView';
import { NoDetailColorText } from '../Text/Text';

export const MainProductView = ({
                                  breadCrumbs,
                                  options,
                                  handler,
                                  listViewToggle,
                                  showPreview,
                                  modelId = null,
                                  hideSelect = false,
                                  useText = null,
                                }) => {
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(1);
  const [color, setColor] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const arModeHandler = useCallback(() => {
    ExportUtils.exportUSDZ();
    listViewToggle(false);
  }, [listViewToggle])

  const selectHandler = useCallback(e => {
    setValue(e.target.value);
    const option = options.find((option, index) => {
      const isSelectedOption = option.id === parseInt(e.target.value);
      if (isSelectedOption) setIndex(index);
      return isSelectedOption;
    });

    setPreviewImage(`/prints/${option.name}.png`)
    setColor(option.option.code);
    handler(option.option);
  }, [handler, options]);

  const nextVariant = () => {
    if (index >= options.length - 1) return;
    const newIndex = index + 1;
    const option = options[newIndex];
    setIndex(newIndex);
    setValue(option.id);
    setColor(option.option.code);
    handler(option.option);
  };
  const prevVariant = () => {
    if (index === 0) return;
    const newIndex = index - 1;
    const option = options[newIndex];
    setIndex(newIndex);
    setValue(option.id);
    setColor(option.option.code);
    handler(option.option);
  }

  useEffect(() => {
    setIndex(0);
    setValue(1);

    if (options.length) {
      setColor(options[0].option.code);
    }
  }, [options]);

  return (
    <div className="main-content">
      <div className="breadCrumbs">{breadCrumbs}</div>
      <div className="main-img">
        <i className="check-btn"/>
        <ModelView/>
        {!modelId && (
          <div className="pre-choose-text">
            Please choose your cover shape:<br/>
            Above Knee or Below Knee
          </div>
        )}
      </div>
      <div className="main-bottom">
        {/*<a href="#" className="second-btn" onClick={arModeHandler}>AR-Mode</a>*/}
        {/*<i className="hb-ico main-ico"/>*/}
        <a className="second-btn" href="/">Start Over</a>
        {useText !== null ? <p style={{ transform: 'translateX(0%)' }}> {useText} </p> : null}
      </div>
      <div className="main-mob">
        <div className="main-mob__top">
          <div className="main-select">
            {!hideSelect ? (
              <>
                <select name="text" onChange={selectHandler} value={value}
                        className={classNames({ with_preview: showPreview })}>
                  {options.map(option => (
                    <option key={Math.random()} value={option.id}>{option.name}</option>
                  ))}
                </select>
                <span className="main-select__num">{index + 1}/{options.length} •</span>
                {showPreview && color && <span className="main-select__preview" style={{ backgroundColor: color }}/>}
                {showPreview && previewImage &&
                  <span className="main-select__preview" style={{ backgroundImage: `url(${previewImage})` }}/>}
              </>
            ) :
              <>
                {NoDetailColorText}
              </>
            }
          </div>
        </div>
        <div className="main-mob__bottom">
          <div className="main-mob__row">
            <button className="main-dots"/>
            <button className="main-ar" onClick={arModeHandler}/>
            <button className="second-btn filter-btn" onClick={() => listViewToggle(true)}>Go to list</button>
          </div>
          <div className="main-arrows main-arrows-second">
            <i className="main-arrow arrow-prev" onClick={prevVariant}/>
            <i className="main-arrow arrow-next" onClick={nextVariant}/>
          </div>
        </div>
      </div>
    </div>
  )
}
