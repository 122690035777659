import React from 'react';
import classNames from 'classnames';
import { BuyButton } from '../buy-button/BuyButton';

export const SettingsScreen = ({ state, setParticularStep, resetSettings }) => {
  return (
    <div className="main-step active" id="step-3">
      <div className="colors active">
        <div
          className={classNames('colors-item', { 'filled': !!state.selectMainColor })}
          onClick={() => setParticularStep('selectMainColor')}
        >
          <span>Main Color</span>
          {state.selectMainColor ? <b>{state.selectMainColor.name}</b> : null}
        </div>
        <div
          className={classNames('colors-item', { 'filled': !!state.selectStrapColor })}
          onClick={() => setParticularStep('selectStrapColor')}
        >
          <span>Strap Color</span>
          {state.selectStrapColor ? <b>{state.selectStrapColor.name}</b> : null}
        </div>
        <div
          className={classNames('colors-item', { 'filled': !!state.selectDetailColor })}
          onClick={() => setParticularStep('selectDetailColor')}
        >
          <span>Detail Color</span>
          {state.selectDetailColor ? <b>{state.selectDetailColor.name}</b> : null}
        </div>
        {state.selectDesign.finishPossible ?
        <div
          className={classNames('colors-item', { 'filled': !!state.selectFinish })}
          onClick={() => setParticularStep('selectFinish')}
        >
          <span>Finish</span>
          {state.selectFinish ? <b>{state.selectFinish.name}</b> : null}
        </div> : null}
      </div>
      <div className="colors-bottom">
        <button onClick={()=>resetSettings()} className="second-btn">Change Colors</button>
        <BuyButton isDisabled={false} />
      </div>
    </div>
  )
}
