import React from 'react';
import classNames from 'classnames';

export const ImageStepper = ({ items, onClick, isSmall, currentId }) => {
  return (
    <div className="main-step active">
      <div className={classNames('main-row', { 'main-row-second': isSmall })}>
        {items.map((item, index) => (
          <div className={classNames('main-col', {'active': item.id === currentId})} key={`${index}${Math.random()}`} onClick={() => onClick(item)}>
            <div className="main-wrap">
              <div className="main-wrap__img"><img src={`/prints-previews/${item.name}_preview.png`} alt=""/></div>
              <span className="main-wrap__name">{item.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
