import * as THREE from 'three';
import { BufferGeometry, MeshStandardMaterial, Object3D } from 'three';
import { USDZExporter } from './USDZExporter';
import { ModelView } from './components/model-view/ModelView';


export class ExportUtils {

  static async exportPrints() {

    const prints = [
      'SOLO',
      'VICTORIAN',
      'L750',
      'ALLENS',
      'ALPINE',
      'APOLLO',
      'BALLER',
      'BLOOM',
      'DECO',
      'DEENER',
      'DEEPEYES',
      'DEMARCHE',
      'DIGICAMO',
      'DIGITALROCOCO',
      'EXIUS2',
      'EXO',
      'FADILAH',
      'FLORA',
      'GAIA',
      'GEOMECON',
      'GLIDE',
      'HI TOP',
      'HIFI',
      'HOUNDS-2',
      'IMPERIALIST',
      'JANZ PRO',
      'JENNINGS',
      'JOHNPAUL',
      'KHLORIS',
      'L-TRAINER',
      'LSDEENER',
      'MODENA',
      'MONARCH',
      'MOTO',
      'MOTO GP',
      'MTL',
      'MYTHIC',
      'NOUVEAU',
      'NOUVEAU COLORWAY',
      'OM',
      'OMEGA',
      'PALMS',
      'PARADIGM',
      'PINES',
      'RACER',
      'ROE2',
      'ROTH',
      'ROYALE',
      'SALT PRO',
      'STEALTH',
      'WINGS',
      'WOODSTOCK',
    ];

    const delay = (milisec) => {
      return new Promise(resolve => {
            setTimeout(() => { resolve('') }, milisec);
      });
    }

    for (let i = 0; i < prints.length; i++) {
      let print = prints[i];
      await ModelView.Instance.loadPrint(`prints/${print}`);
      await delay(100);
      ExportUtils.exportScreenshot(print + "_preview", Math.PI/2-Math.PI/8);
      await delay(100);
    }
  }

  static exportScreenshot(name, rot=0) {

    ModelView.Instance.model.rotation.y = rot;
    ModelView.Instance.animate();
    ModelView.Instance.animate();
    ModelView.Instance.animate();

    let canvas = document.querySelector("canvas");

    let url = canvas.toDataURL('image/png', 1.0);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.download = `${name}.png`;

    link.href = url;
    link.click();

  }

  static async exportUSDZ() {

    if (ModelView.Instance == null || ModelView.Instance.model == null) return;

    let newMaterial;

    const clone = ModelView.Instance.model.clone(true);

    clone.traverse((mesh) => {
      
      let mats = mesh.material;

      if (mesh.name === "Base" && mats != null) {
        
          const geo = mesh.geometry.clone();

          geo.clearGroups();
          geo.addGroup(0, 130000, 0);

          const exportSize = 4096;

          mesh.geometry = geo;

          let exportCanvas = document.getElementById('export-canvas');

          // плющим текстуру
          
          if (exportCanvas) exportCanvas.outerHTML = '';
          exportCanvas = document.createElement('canvas');
          exportCanvas.id = 'export-canvas';
          exportCanvas.style.display = 'none';
          exportCanvas.width = exportSize;
          exportCanvas.height = exportSize;

          document.body.appendChild(exportCanvas);

          let ctx = exportCanvas.getContext('2d');
          
          if(!ModelView.Instance.printName.includes("NOUVEAU COLORWAY"))
          {
            ctx.globalCompositeOperation = "source-over";
            ctx.fillStyle = mats[1].color.getStyle();
            ctx.fillRect(0, 0, exportCanvas.width, exportCanvas.height);
          
        
            // draw print
            ctx.fillStyle = mats[1].color.getStyle();
          
            ctx.globalCompositeOperation = "destination-in";
          }
          ctx.drawImage(mats[1].map.image, 0, 0);
          ctx.globalCompositeOperation = "source-over";


          // Append background

          let exportCanvas2 = document.getElementById('export-canvas-2');
          if (exportCanvas2) exportCanvas2.outerHTML = '';
          exportCanvas2 = document.createElement('canvas');
          exportCanvas2.id = 'export-canvas-2';
          exportCanvas2.style.display = 'none';
          exportCanvas2.width = exportSize;
          exportCanvas2.height = exportSize;

          document.body.appendChild(exportCanvas2);

          ctx = exportCanvas2.getContext('2d');
          
          ctx.globalCompositeOperation = "source-over";
          ctx.fillStyle = mats[0].color.getStyle();
          ctx.fillRect(0, 0, exportCanvas2.width, exportCanvas2.height);
        
          // draw print
          ctx.drawImage(exportCanvas, 0, 0);

          const overlayTexture = new THREE.CanvasTexture(exportCanvas2);
          overlayTexture.wrapS = THREE.ClampToEdgeWrapping;
          overlayTexture.wrapT = THREE.ClampToEdgeWrapping;
          newMaterial = new THREE.MeshStandardMaterial({transparent: true,
            color: "#fff",
            metalness: mats[0].metalness,
            roughness: mats[0].roughnessMap === null ? 1 : mats[0].roughness,
            normalScale: new THREE.Vector2(0.1, 0.1),
            map: overlayTexture,
            alphaMap: mats[0].alphaMap,
            roughnessMap: mats[0].roughnessMap,
            // normalMap: mats[0].normalMap,
          });
        
          newMaterial.needsUpdate = true;

          mesh.material = newMaterial;
        
      }
    });

    // USDZ
    const exporter = new USDZExporter();
    const arraybuffer = await exporter.parse(clone);
    const blob = new Blob([arraybuffer], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.style.display = 'none';
    link.rel = 'ar';
    link.download = 'asset.usdz';
    const i = ExportUtils.makeImage();
    link.appendChild(i);
    document.body.appendChild(link);

    link.href = URL.createObjectURL(blob);
    link.click();

    // cleanup
    setTimeout(()=>{
      let exportCanvas = document.getElementById('export-canvas');
      if (exportCanvas) exportCanvas.outerHTML = '';
      exportCanvas = document.getElementById('export-canvas-2');
      if (exportCanvas) exportCanvas.outerHTML = '';
    }, 150);
  }

  static makeImage() {
    const c = document.createElement('canvas');
    
    const i = new Image();
    i.src = c.toDataURL();
    return i;
  }
}