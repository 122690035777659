import classNames from 'classnames';
import React from 'react';

export const ModelStepper = ({ items, onClick, currentId }) => {

  return (
    <div className="main-step active">
      <div className={classNames('main-row')}>
        {items.map((item, index) => (
          <div className={classNames('main-col model', { 'active': item.id === currentId })}
               key={`${index}${Math.random()}`}
               onClick={() => onClick(item)}>
            <div className="main-wrap">
              <div className="main-wrap__img"><img src={item.img} alt=""/></div>
              <span className="main-wrap__name">{item.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
