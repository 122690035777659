import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { get } from './api.service';
import { Menu } from './components/menu/menu';
import { BuyButton } from './components/buy-button/BuyButton';
import { ModelView } from './components/model-view/ModelView';
import { ColorStepper } from './components/stepper/ColorStepper';
import { ImageStepper } from './components/stepper/ImageStepper';
import { SettingsScreen } from './components/stepper/SettingsScreen';
import { BigColorStepper } from './components/stepper/BigColorStepper';
import { MainProductView } from './components/main-product-view/MainProductView';
import { ModelStepper } from './components/stepper/ModelStepper';
import { Text } from './components/Text/Text';

const empty_state = {
  selectMainColor: null,
  selectModel: null,
  selectType: null,
  selectDesign: null,
  selectStrapColor: null,
  selectFinish: null,
  selectDetailColor: null,
}

const modelIds = [1, 3];

const defaults = {
  selectMainColor: 'White',
  selectStrapColor: 'Tan',
  selectDesign: 'SOLO',
  selectDetailColor: 'Black',
  selectFinish: 'Original',
};

function App() {
  const [isListActive, setIsListActive] = useState(false);
  const [options, setOptions] = useState([])
  const [colors, setColors] = useState([])
  const [finishes, setFinishes] = useState([])
  const [strapColors, setStrapColors] = useState([])
  const [designs, setDesigns] = useState([])
  const [types, setTypes] = useState([])
  const [step, setStep] = useState(null);
  const [products, setProducts] = useState([])
  const [breadCrumbs, setBreadCrumbs] = useState('');
  const [typeIds, setTypeIds] = useState([2, 4]);

  const [state, setState] = useState(empty_state);

  const [STEPS] = useState({
    selectModel: {
      type: 'selectModel',
      header: 'Select Shape',
      showPreview: false,
      nextStep: 'selectType',
      prevStep: null,
      breadCrumbsPostfix: '',
      initialCallback: () => {
        prepareOptions(products, 'name', 'id')
      }
    },
    selectType: {
      type: 'selectType',
      header: 'Select Back',
      showPreview: false,
      nextStep: 'selectDesign',
      prevStep: 'selectModel',
      breadCrumbsPostfix: '',
      initialCallback: async (state, colors, strapColors, finishes, designs, types) => {
        prepareOptions(types, 'name', 'id');
        if (!!state.selectType) return;
        const defaultType = types.find(filter => filter.default);
        await ModelView.Instance.loadModel(defaultType.modelId);
        setState({
          ...state,
          selectType: defaultType
        })
      }
    },
    selectDesign: {
      type: 'selectDesign',
      header: 'Select Design',
      showPreview: false,
      nextStep: 'selectMainColor',
      prevStep: 'selectType',
      breadCrumbsPostfix: '',
      initialCallback: (state, colors, strapColors, finishes, designs) => {
        prepareOptions(designs, 'name', 'id');
        if (!!state.selectDesign) return;
        const design = designs.find(designItem => designItem.name === defaults['selectDesign'])
        onDesignChoose(design);
        setState({
          ...state,
          selectDesign: design
        })
      }
    },
    selectMainColor: {
      type: 'selectMainColor',
      header: 'Select Main Color',
      showPreview: true,
      nextStep: 'selectDetailColor',
      prevStep: 'selectDesign',
      breadCrumbsPostfix: 'Main',
      initialCallback: (state, colors, strapColors, finishes, designs) => {
        prepareOptions(colors, 'name', 'id')
        if (!!state.selectMainColor) return;
        const mainColor = colors.find(item => item.name === defaults['selectMainColor'])
        onMainColorChoose(mainColor)
        setState({
          ...state,
          selectMainColor: mainColor
        })
      }
    },
    selectDetailColor: {
      type: 'selectDetailColor',
      header: 'Select Detail Color',
      showPreview: true,
      prevStep: 'selectMainColor',
      nextStep: 'selectFinish',
      breadCrumbsPostfix: 'Details',
      initialCallback: (state, colors, strapColors, finishes, designs) => {
        prepareOptions(colors, 'name', 'id')
        if (!!state.selectDetailColor) return;
        const detailColor = colors.find(item => item.name === defaults['selectDetailColor'])
        if(state.selectDesign.detailColorPossible)
          onDetailChoose(detailColor)
        setState({
          ...state,
          selectDetailColor: detailColor
        })
      }
    },
    selectFinish: {
      type: 'selectFinish',
      header: 'Select Finish',
      showPreview: true,
      prevStep: 'selectDetailColor',
      nextStep: 'selectStrapColor',
      breadCrumbsPostfix: 'Finish',
      initialCallback: (state, colors, strapColors, finishes, designs) => {
        prepareOptions(finishes, 'name', 'id')
        if (!!state.selectFinish) return;
        const finish = finishes.find(item => item.name === defaults['selectFinish'])
        
        if(state.selectDesign.finishPossible)
          onFinishChoose(finish);
        setState({
          ...state,
          selectFinish: finish
        })
        
      }
    },
    selectStrapColor: {
      type: 'selectStrapColor',
      header: 'Select Strap Color',
      showPreview: true,
      nextStep: 'settingsResult',
      prevStep: 'selectFinish',
      breadCrumbsPostfix: 'Straps',
      initialCallback: (state, colors, strapColors, finishes, designs) => {
        prepareOptions(strapColors, 'name', 'id')
        if (!!state.selectStrapColor) return;
        const strapColor = strapColors.find(item => item.name === defaults['selectStrapColor'])
        onStrapColorChoose(strapColor)
        setState({
          ...state,
          selectStrapColor: strapColor
        })
      }
    },
    settingsResult: {
      type: 'settingsResult',
      header: 'Settings',
      prevStep: 'selectFinish',
      breadCrumbsPostfix: '',
      initialCallback: () => {
      }
    },
  })

  const prepareOptions = useCallback((items, nameKey, idKey) => {
    const options = items.map((item) => ({
      name: item[nameKey],
      id: item[idKey],
      option: item
    }));
    setOptions(options);
  }, []);

  const fillOptions = useCallback((step) => {
    switch (step) {
      case STEPS.selectModel.type:
        prepareOptions(products.filter(item => modelIds.includes(item.id)), 'name', 'id')
        break;
      case STEPS.selectType.type:
        prepareOptions(types, 'name', 'id')
        break;
      case STEPS.selectMainColor.type:
        prepareOptions(colors, 'name', 'id')
        break;
      case STEPS.selectStrapColor.type:
        prepareOptions(strapColors, 'name', 'id')
        break;
      case STEPS.selectFinish.type:
        prepareOptions(finishes, 'name', 'id')
        break;
      case STEPS.selectDesign.type:
        prepareOptions(designs, 'name', 'id')
        break;
      case STEPS.selectDetailColor.type:
        prepareOptions(colors, 'name', 'id')
        break;
      default:
        return;
    }
  }, [colors, strapColors, finishes, designs, products, types]);

  const prevScreen = useCallback((step) => {
    if (!step.prevStep) return;
    const prevStep = step.prevStep === 'selectFinish' && !state.selectDesign.finishPossible ? STEPS[STEPS[step.prevStep].prevStep] : STEPS[step.prevStep];
    setStep(prevStep);
    fillOptions(step.prevStep);
  }, [state, fillOptions]);

  const nextScreen = (step) => {
    if (!step.nextStep || !state.selectModel?.id) return;
    const nextStep = step.nextStep === 'selectFinish' && !state.selectDesign.finishPossible ? STEPS[STEPS[step.nextStep].nextStep] : STEPS[step.nextStep];
    
    nextStep.initialCallback(state, colors, strapColors, finishes, designs, types);
    setStep(nextStep);
    fillOptions(step.nextStep);
  };

  const setParticularStep = useCallback((stepProperty) => {
    setStep(STEPS[stepProperty]);
    fillOptions(stepProperty);
  }, [fillOptions, setStep])

  const resetSettings = useCallback(() => {
    
    const defaultMainColor = colors.find(item => item.name === defaults['selectMainColor'])
    const defaultDetailColor = colors.find(item => item.name === defaults['selectDetailColor'])
    const defaultStrapColor = strapColors.find(item => item.name === defaults['selectStrapColor'])
    const defaultFinish = finishes.find(item => item.name === defaults['selectFinish'])

    setState({
      ...state,
      selectMainColor: defaultMainColor,
      selectStrapColor: defaultStrapColor,
      selectFinish: defaultFinish,
      selectDetailColor: defaultDetailColor,
    });

    // defaults
    ModelView.Instance.setMainColor("#FFFFFF");
    ModelView.Instance.setStrapColor("#D3D2A6");
    ModelView.Instance.setOverlayColor("#383A3D");
    
    const designName = state.selectDesign.name.toUpperCase();
    if(designName.includes("LSDEENER")) {
      ModelView.Instance.setMainMaterial(3);
    }
    else {
      ModelView.Instance.setMainMaterial(1);
    }

  }, [state, setState, defaults]);

  const onModelChoose = useCallback(async (model) => {

    const defaultType = model.types.find(filter => filter.default);
    await ModelView.Instance.loadModel(defaultType.modelId);

    // defaults
    ModelView.Instance.setMainColor("#FFFFFF");
    ModelView.Instance.setStrapColor("#D3D2A6");
    ModelView.Instance.setOverlayColor("#383A3D");
    ModelView.Instance.setMainMaterial(1);
    await ModelView.Instance.loadPrint(`prints/SOLO`);

    setDesigns(model.designs);
    setColors(model.mainColors);
    setFinishes(model.finishes);
    setStrapColors(model.strapColors);
    setTypes(model.types);

    setState({
      ...state,
      selectModel: model
    })
  }, [setState]);

  const onTypeChoose = useCallback(async (model) => {

    await ModelView.Instance.loadModel(model.modelId);
    // defaults
    ModelView.Instance.setMainColor("#FFFFFF");
    ModelView.Instance.setStrapColor("#D3D2A6");
    ModelView.Instance.setOverlayColor("#383A3D");
    ModelView.Instance.setMainMaterial(1);
    await ModelView.Instance.loadPrint(`prints/SOLO`);

    setState({
      ...state,
      selectType: model
    })
  }, [state, setState]);

  const onMainColorChoose = useCallback(color => {
    ModelView.Instance.setMainColor(color.codeMain);
  }, []);

  const onStrapColorChoose = useCallback(color => {
    ModelView.Instance.setStrapColor(color.code);
  }, []);

  const onDesignChoose = useCallback(async (design) => {
    const designName = design.name.toUpperCase();

    await ModelView.Instance.loadPrint(`prints/${designName}`);
    console.log(designName);

    if(designName.includes("LSDEENER")) {
      ModelView.Instance.setMainMaterial(3);
    }
    else {
      ModelView.Instance.setMainMaterial(state.selectFinish?.id ?? 1);
      ModelView.Instance.setOverlayMaterial(1);
    }
    
    if(designName.includes("OMEGA")){
      ModelView.Instance.setMainMaterial(1);
      ModelView.Instance.setOverlayMaterial(3);
    }
    else {
      if(ModelView.Instance.roughnessMapName !== ModelView.Instance.defaultRoughnessMapName)
        await ModelView.Instance.loadRougnessMap(ModelView.Instance.defaultRoughnessMapName)
      ModelView.Instance.setMainMaterial(state.selectFinish?.id ?? 1);
      ModelView.Instance.setOverlayMaterial(1);
    }
  }, [state]);

  const onDetailChoose = useCallback(color => {
    ModelView.Instance.setOverlayColor(color.codeDetail);
  }, []);

  const onFinishChoose = useCallback(finish => {
    ModelView.Instance.setMainMaterial(finish.id);
  }, []);

  const handler = option => {
    setState({
      ...state,
      [step.type]: option
    })

    switch (step.type) {
      case STEPS.selectModel.type:
        onModelChoose(option);
        break;
      case STEPS.selectType.type:
        onTypeChoose(option);
        break;
      case STEPS.selectMainColor.type:
        onMainColorChoose(option);
        break;
      case STEPS.selectStrapColor.type:
        onStrapColorChoose(option);
        break;
      case STEPS.selectFinish.type:
        onFinishChoose(option);
        break;
      case STEPS.selectDesign.type:
        onDesignChoose(option);
        break;
      case STEPS.selectDetailColor.type:
        onDetailChoose(option);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    setStep(STEPS.selectModel);
    get({
      url: 'models'
    }).then(async (models) => {
      setProducts(models);
      prepareOptions(models.filter(item => modelIds.includes(item.id)), 'name', 'id');
    })
  }, [prepareOptions, onModelChoose]);

  useEffect(() => {
    const keys = [
      'selectModel',
      'selectType',
      'selectDesign',
      'selectMainColor',
      'selectDetailColor',
      'selectFinish',
      'selectStrapColor'
    ];
    let breadCrumbsString = '';
    keys.forEach((key, index) => {
      breadCrumbsString += state[key] ? `${state[key].name} ${STEPS[key].breadCrumbsPostfix}` : '';
      if (index !== keys.length && state[key]) {
        breadCrumbsString += ' / ';
      }
    });
    setBreadCrumbs(breadCrumbsString)
  }, [state]);

  const getText = (design) => {
    if(design.name === "LSDEENER") {
      return "Clients will be able to select a: Main, Detail, Sunray 1, Sunray 2, Shin, Top Cuff, & Star Color. The LSDeener only comes in the original finish.";  
    }
    if(design.name === "OMEGA") {
      return "The Omega comes in a default finish. The shin detail is High-Gloss and the rest of the cover is Matte.";
    }
  }

  if (!step) return <></>;

  return (
    <>
      <Menu/>
      <section className="main">
        <MainProductView
          breadCrumbs={breadCrumbs}
          options={options}
          handler={handler}
          listViewToggle={setIsListActive}
          showPreview={step.showPreview}
          modelId={state.selectModel?.id}
          hideSelect={step.type === STEPS.selectDetailColor.type && !state.selectDesign?.detailColorPossible}
          useText={step.type === STEPS.selectDetailColor.type && state.selectDesign.detailColorText ? getText(state.selectDesign) : null}
        />
        <div className="main-sidebar">
          <div className="main-top">
            <span className="main-name">{step.header}</span>
            <div className="main-arrows">
              <i className="main-arrow arrow-prev" onClick={() => prevScreen(step)}/>
              <i
                className={classNames('main-arrow', 'arrow-next', { active: !!step.nextStep && !!state.selectModel?.id })}
                onClick={() => nextScreen(step)}/>
            </div>
          </div>
          <div className={classNames('main-all', {
            active:
              isListActive || step.type === STEPS.settingsResult.type
          })}>
            <div className="main-steps">
              {step.type === STEPS.settingsResult.type &&
                <SettingsScreen state={state} setParticularStep={setParticularStep} resetSettings={resetSettings}/>}
              {step.type === STEPS.selectModel.type ?
                <ModelStepper items={products} onClick={handler}
                              currentId={state.selectModel?.id}/> : null}
              {step.type === STEPS.selectType.type ?
                <ModelStepper items={types} onClick={handler}
                              currentId={state.selectType?.id}/> : null}
              {step.type === STEPS.selectMainColor.type ?
                <ColorStepper items={colors} onClick={handler} currentId={state.selectMainColor?.id}/> : null}
              {step.type === STEPS.selectStrapColor.type ?
                <BigColorStepper items={strapColors} onClick={handler} currentId={state.selectStrapColor?.id}/> : null}
              {step.type === STEPS.selectFinish.type ?
                <BigColorStepper items={finishes} onClick={handler} currentId={state.selectFinish?.id}/> : null}
              {step.type === STEPS.selectDesign.type ?
                <ImageStepper isSmall items={designs} onClick={handler} currentId={state.selectDesign?.id}/> : null}
              {step.type === STEPS.selectDetailColor.type && state.selectDesign?.detailColorPossible ?
                <ColorStepper items={colors} onClick={handler} codeProperty={'codeDetail'} currentId={state.selectDetailColor?.id}/> : null}
              {step.type === STEPS.selectDetailColor.type && !state.selectDesign?.detailColorPossible ?
                <Text/> : null}
            </div>
            <div className="main-sidebar__mob">
              {step.type === STEPS.settingsResult.type ?
                <button className="second-btn">Reset settings</button> : null}
              {step.type !== STEPS.settingsResult.type ?
                <button className="second-btn" onClick={() => setParticularStep(STEPS.settingsResult.type)}>Go to
                  settings</button> : null}
              <i className="hb-ico ico-360" onClick={() => setIsListActive(false)}/>
              <BuyButton isDisabled={true}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
