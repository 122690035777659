import * as THREE from 'three';
import View from "./View.js"

export class ModelView extends View {

  static Instance = null;
  // THREE.Group model
  model = null;
  models = {};
  normalMap = null;
  roughnessMap = null;

  isLoading = false;

  isSameColorOffset = false;

  baseColor = "";
  detailColor = "";
  printName = "";
  sub = "";

  roughnessMapName = "";

  defaultRoughnessMapName = "/models-support/normal.png";

  modelId = -1;

  textureLoader = new THREE.TextureLoader();

  constructor() {
    super()

    ModelView.Instance = this;

    // init controls
    const c = this.controls;
    c.enableKeys = false;
    c.minDistance = 0.16;
    c.maxDistance = 0.25;
    c.rotateSpeed = 1;
    c.enableZoom = true;
    c.enablePan = false;

    this.resetCamera();
    
    this.camera.layers.enable(10);
  }

  resetCamera()
  {
    this.camera.position.set(15, 7.5, 30);
  }

  async loadPrint(name) {
    if (this.isLoading) return;
    this.isLoading = true;
    if(name.includes("VICTORIAN")){
      await this.loadModel(this.modelId, "_VICTORIAN");
    }
    else if(this.sub === "_VICTORIAN"){
      await this.loadModel(this.modelId);
    }

    const map = await this.textureLoader.loadAsync(name + ".png");
    const alphaMap = await this.textureLoader.loadAsync(name + "_ALPHAMAP.png");

    map.flipY = false;
    this.overlayMaterial.map = map;
    this.overlayMaterial.map.needsUpdate = true;
    this.overlayMaterial.needsUpdate = true;

    alphaMap.flipY = false;

    this.overlayMaterial.alphaMap = alphaMap;
    this.overlayMaterial.needsUpdate = true;
    this.baseMaterial.alphaMap = alphaMap;
    this.baseMaterial.alphaMap.needsUpdate = true;
    this.baseMaterial.needsUpdate = true;

    this.printName = name;
    this.isLoading = false;

    this.isSameColorOffset = false;

    if(this.printName.includes("NOUVEAU COLORWAY"))
    {
      this.overlayMaterial.color.set(new THREE.Color(0xffffff));
      this.overlayMaterial.needsUpdate = true;
    }
    // if(this.printName.endsWith("MOTO"))
    // {
    //   this.setOverlayColor("#"+this.baseMaterial.color.getHexString());
    // }
    // else {
      this.applySameColor();
    // }


    
  }

  async loadModel(id, sub="") {

    this.sub = sub;
    this.isSameColorOffset = false;
    this.modelId = id;
    this.resetCamera();

    // workaround fix side with model 4
    if (id == 4) {
      this.baseMaterial.side = THREE.DoubleSide;
    } else {
      this.baseMaterial.side = THREE.FrontSide;
    }

    for (var i = this.scene.children.length - 1; i >= 0; i--) {
      if (this.scene.children[i].type === "Group") {
        this.scene.remove(this.scene.children[i]);
      }
    }

    let name = `/models/Alphine ${id}${sub}.glb`;

    //Models cache .Remove if will any memory troubles
    if (this.models[name]) {
      this.scene.remove(this.model);
      this.scene.add(this.models[name]);
      this.model = this.models[name];
      return;
    }

    this.model = await this.loadModelAsync(name);
    this.models[name] = this.model;

    if(this.roughnessMapName !== this.defaultRoughnessMapName)
        this.loadRougnessMap(this.defaultRoughnessMapName)
    
  }

  async loadRougnessMap(name) {
    
    this.roughnessMap = await new THREE.TextureLoader().loadAsync(name);
    this.roughnessMap.wrapS = THREE.RepeatWrapping;
    this.roughnessMap.wrapT = THREE.RepeatWrapping;
    this.roughnessMap.repeat.set(1, 1);
    this.baseMaterial.normalMap = this.roughnessMap;
    this.baseMaterial.needsUpdate = true;

    this.roughnessMapName = name;

    console.log("loadRougnessMap " + this.roughnessMapName);;
  }

  setOverlayColor(color) {

    this.overlayMaterial.color.set(color);
    this.overlayMaterial.needsUpdate = true;

    this.detailColor = color;

    this.applySameColor();
  }

  applySameColor() {
    // if(this.printName.endsWith("MOTO")) return;

    // if (!this.isSameColorOffset && this.baseColor.toUpperCase() === this.detailColor.toUpperCase()) {

    //   this.overlayMaterial.color.offsetHSL(0, 0, -0.3);
    //   this.overlayMaterial.needsUpdate = true;
    //   this.isSameColorOffset = true;

    // } else if (this.isSameColorOffset && this.baseColor.toUpperCase() !== this.detailColor.toUpperCase()) {
      
    //   this.overlayMaterial.color.offsetHSL(0, 0, 0.3);
    //   this.overlayMaterial.needsUpdate = true;
    //   this.isSameColorOffset = false;
    // }
  }

  setStrapColor(color) {

    this.strapMaterial.color.set(color);
    this.strapMaterial.needsUpdate = true;
  }

  setMainColor(color) {

    this.baseMaterial.color.set(color);
    this.baseMaterial.needsUpdate = true;
    this.baseColor = color;

    this.isSameColorOffset = false;

    //if(this.printName.includes("MOTO"))
    //{
      //this.setOverlayColor("#"+this.baseMaterial.color.getHexString());
    //}

    this.applySameColor();
  }


  setMainMaterial(type) {
    console.log("setMainMaterial " + type);;
    
    if (type === 1) {
      this.baseMaterial.metalness = 0.0;
      this.baseMaterial.roughness = 1.0;
    } else if (type === 2) {
      this.baseMaterial.metalness = 0.0;
      this.baseMaterial.roughness = 0.9;
    } else if (type === 3) {
      this.baseMaterial.metalness = 0.3;
      this.baseMaterial.roughness = 0.1;
    }

    if (type === 1) {
      this.baseMaterial.normalMap = this.roughnessMap;
      this.baseMaterial.roughnessMap = this.roughnessMap
      this.baseMaterial.needsUpdate = true;
    } else {
      this.baseMaterial.normalMap = null;
      
      if(this.roughnessMapName === this.defaultRoughnessMapName)
        this.baseMaterial.roughnessMap = null
      else{
        console.log("set " + this.roughnessMapName);;
        this.baseMaterial.roughnessMap = this.roughnessMap
        console.log(this.baseMaterial.roughnessMap);
      }

      this.baseMaterial.needsUpdate = true;
    }
  }

  setOverlayMaterial(type) {
    console.log("setOverlayMaterial " + type);;
    
    if (type === 1) {
      this.overlayMaterial.metalness = 0.5;
      this.overlayMaterial.roughness = 0.5;
    } else if (type === 2) {
      this.overlayMaterial.metalness = 0.0;
      this.overlayMaterial.roughness = 0.9;
    } else if (type === 3) {
      this.overlayMaterial.metalness = 0.3;
      this.overlayMaterial.roughness = 0.1;
    }


    this.overlayMaterial.needsUpdate = true;
  }

  render() {
    return super.render();
  }

}
